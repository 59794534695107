.select-all-chkbox {
    margin-top: 14px;
    margin-left: 10px;
}


.select-all-chklbl {
    margin-top: 16px;
    margin-left: 4px;
}







.cc .p-inputtext {
    width: 100%;

}




.sms-template-txt {
    padding: 10px;
    font: normal normal normal 13px/20px Nunito Sans;
    color: var(--shadow-10);
    margin: 2px;
    border-radius: 5px;
    line-height: 50px;
    box-shadow: 0px 3px 6px var(--shadow-85);
    border: 0.5px solid var(--theme-85);
    min-height: 100px;
}