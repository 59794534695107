h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
}

.sp-container {
    padding: 0px 10%;
    font-family: Rubik, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;

}


.sp-container-fluid {
    padding-top: 50px;
    padding-bottom: 30px;
}

// .intro-section {
//     min-height: calc(100vh - 100px);
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
// }

// .intro-section h1 {
//     font-size: 70px;
//     font-family: "Nunito Sans", sans-serif;
//     font-weight: 500;
// }

// .intro-section h1:first-child {
//     margin-top: 20%;
//     margin-bottom: 0px;
// }


.intro-section h1.caption {
    margin-top: 0px;
}

.sp-mt15 {
    margin-top: 15%;
}

.title-s1 {
    font-size: 50px;
}

.sp-card1 {
    background: white 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border: 0.2px solid #d9d9d9;
    border-radius: 10px;
    transform: scale(1);
    transition: all 0.5s ease 0s;
    padding: 20px;
}

.sp-card1:hover {
    transform: scale(1.01);
    z-index: 1;
}

.sp-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}



.bulk-sms-w {
    /* background-color: whitesmoke; */
    //  padding-top: 6%;
    padding-bottom: 3%;
}

.bulk-sms-w .promotional-sms {
    margin-top: 30px;
    padding: 40px;
}

.bulk-sms-w .transactional-sms {
    margin-top: 50px;
    padding: 40px;
}

.bulk-email-w {
    background-color: var(--shadow-95);
    //  padding-top: 3%;
    padding-bottom: 3%;
}

a {
    text-decoration: none;
}

.pricing-contianer-w .pricing-contianer {
    margin-top: 40px;
}

.pricing-type {
    margin-top: 80px;
    font-size: 24px;
}

.pricing-contianer-w .plans-txt span {
    font-weight: bold;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.pricingTable {
    text-align: center;
    background: #fff;
    /* margin: 0 -15px; */
    box-shadow: 0 0 10px var(--shadow-65);
    padding-bottom: 40px;
    border-radius: 10px;
    color: #cad0de;
    transform: scale(1);
    transition: all 0.5s ease 0s;
    margin: 0px;
    margin-left: 5px;
    margin-right: 5px;
}

.pricingTable:hover {
    transform: scale(1.05);
    z-index: 1;
}


.pricingTable .pricingTable-header {
    padding: 40px 0;
    background: #f5f6f9;
    border-radius: 10px 10px 50% 50%;
    transition: all 0.5s ease 0s;
    margin-bottom: 30px;
}

.pricingTable:hover .pricingTable-header {
    background: var(--theme-45);
}

.pricingTable .pricingTable-header i {
    font-size: 50px;
    color: var(--shadow-55);
    margin-bottom: 10px;
    transition: all 0.5s ease 0s;
}

.pricingTable .price-value {
    font-size: 35px;
    color: var(--theme-10);
    transition: all 0.5s ease 0s;
}

.pricingTable .quantity {
    display: block;
    font-size: 14px;
    color: #7282a7;
}

.pricingTable:hover .quantity,
.pricingTable:hover .price-value,
.pricingTable:hover .pricingTable-header i {
    color: var(--theme-100);
}

.pricingTable .gst {
    display: block;
    font-size: 14px;
    color: var(--theme-85);
}

.pricingTable:hover .gst,
.pricingTable:hover .price-value,
.pricingTable:hover .pricingTable-header i {
    color: #fff;
}

.pricingTable .heading {
    font-size: 24px;
    color: var(--theme-10);
    margin-bottom: 20px;
    text-transform: uppercase;
}

.pricingTable .pricing-content ul {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
}

.pricingTable .pricing-content ul li {
    line-height: 30px;
    color: #a7a8aa;
}

.pricingTable .pricingTable-signup a {
    display: inline-block;
    font-size: 15px;
    color: #fff;
    padding: 10px 35px;
    border-radius: 20px;
    background: var(--theme-45);
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
}

.pricingTable .pricingTable-signup a:hover {
    box-shadow: 0 0 10px var(--theme-10);
}

.pricingTable.blue .heading,
.pricingTable.blue .price-value {
    color: var(--theme-45);
}


.pricingTable.blue:hover .price-value,
.pricingTable.green:hover .price-value,
.pricingTable.red:hover .price-value,
.pricingTable.pink:hover .price-value,
.pricingTable.lgreen:hover .price-value {
    color: #fff;
}

@media screen and (max-width: 990px) {
    .pricingTable {
        margin: 0 0 20px;
    }
}



.ma-mt50 {
    margin-top: 50px;
}

.sp-accordian {
    margin-top: 40px;
}


.sp-accordian .card-header {
    margin-bottom: 0;
    padding: 0px;
    /* background-color: rgba(0, 0, 0, 0.01); */
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.sp-accordian .heading-s1 {
    padding: 1rem 1.25rem;
    /* font-size: 15px; */
    font-family: "Maven Pro", sans-serif;
    margin-top: 10px;
    cursor: pointer;
}

.sp-accordian .card-body {
    font-family: "Maven Pro", sans-serif;
    font-size: 14px;
    margin-left: 20px;
}

.why-sendshortly-w {
    background-color: var(--shadow-95);
}

.why-sendshortly-w .why-sendshortly {
    // padding: 40px 0px;
}

.sendshortly-sounds-w {
    background-color: var(--theme-98);
}

.sounds-section {
    // padding: 50px 0px;
}

.faqs-contianer-w {
    margin-top: 40px;
    background-color: var(--theme-98);
}

.faqs-contianer-w .faqs-contianer {
    // padding: 40px 0px 50px;
    padding-bottom: 50px;
}

.case-studies-contianer-w {
    margin-top: 40px;
}

.read-more {
    margin-top: 30px;
    position: relative;
}

.read-more a {
    /* display: inline-block; */
    /* position: absolute; */
    //left: 50%;
    text-align: center;
    font-size: 15px;
    color: var(--theme-100);
    padding: 10px 35px;
    // margin: 50px;
    border-radius: 20px;
    background: var(--primary-color);
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    text-decoration: none;
}

.read-more a:hover {
    box-shadow: 0 0 10px var(--primary-color-dark);
}



.more-price {
    text-align: center;
    background: var(--theme-100);
    /* margin: 0 -15px; */
    box-shadow: 0 0 10px var(--shadow-65);
    padding-bottom: 40px;
    border-radius: 10px;
    transform: scale(1);
    transition: all 0.5s ease 0s;
    padding: 30px;
    margin: 5px;
    margin-top: 50px;
}

.more-price:hover {
    transform: scale(1.05);
    z-index: 1;
}



.sp-points li {
    margin-bottom: 14px;
}


.case-studies .case-study {
    background: #fff;
    box-shadow: 0 0 10px var(--shadow-65);
    padding-bottom: 40px;
    border-radius: 10px;
    transform: scale(1);
    transition: all 0.5s ease 0s;
    padding: 30px;
    margin: 5px;
    margin-top: 50px;
    cursor: pointer;
}

.case-studies .case-study:hover {
    transform: scale(1.11);
    z-index: 1;
}

.sp-mt10 {
    margin-top: 10px;
}

.sms-intro {

    .highlight-txt {
        font-size: 17px;
        margin: 4px 0px;
        margin-left: 20px;

    }
}

.sms-intro .highlight-txt::before {
    content: "-  ";
}

.sp-plain-card {
    background: var(--theme-98);
    /* margin: 0 -15px; */
    /* box-shadow: 0 0 10px #ababab; */
    padding-bottom: 40px;
    border-radius: 16px;
    transform: scale(1);
    transition: all 0.5s ease 0s;
    padding: 30px;
    margin: 5px;
    // margin-top: 50px;
    cursor: pointer;
}

.sms-news-w {
    background: var(--theme-98);
    /* margin: 0 -15px; */
    /* box-shadow: 0 0 10px #ababab; */
    /* padding-bottom: 40px; */
    border-radius: 16px;
    transform: scale(1);
    transition: all 0.5s ease 0s;
    padding: 30px;
    margin: 5px;
    margin-top: 50px;
}

.speciality-card {
    min-height: 180px;
}

.why-is-bulksms-w {
    background-color: var(--shadow-95);
}

.why-is-bulksms {
    padding: 50px 0px;
}



.our-features div.row {
    margin-top: 40px;
}

.our-features h2 {
    text-align: center;
    margin-bottom: 50px;
}

.our-features p {
    width: 80%;
}

.feature-title {
    font-size: 20px;
}

.voice-sms-w {
    background-color: var(--theme-98);
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 100px;
}

.sp-whitesmoke {
    background-color: var(--shadow-95);
    padding-top: 20px;
    padding-bottom: 20px;
}


.sp-container .intro-section p {
    color: var(--theme-100);
    font-size: 22px;
    word-break: normal;

}

.intro-section {
    /* display: table; */
    min-height: calc(100vh);
    vertical-align: middle;

}

.intro-section .contents {
    display: table-cell;
    /* display: block; */
}

.intro-section h1 {
    font-size: 64px;
    color: var(--theme-100);
    /* white-space: nowrap; */

    font-family: Rubik, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.intro-section h1:first-child {
    margin-top: 18%;
}

.landing-image {
    border-radius: 18px;
    /* display: block; */
    margin-left: auto;
    margin-right: auto;
    /* width: 50%; */
    opacity: 0.8;
    /* margin-top: 50%; */
}



// .sp-mt15 {
//     margin-top: 15%;
// }

.sp-container .title-s1 span {
    background-color: var(--theme-45);
    opacity: 1;
    color: var(--theme-100);
    font-weight: 700;
    font-family: SFMono-Regular, SF Mono, Segoe UI Mono, Roboto Mono, Ubuntu Mono,
        Menlo, Consolas, Courier, monospace;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.5rem;
}

.title-s1 {
    font-size: 40px;
    line-height: 1.15;
    margin-bottom: 20px;
    color: var(--theme-10);
    font-weight: 500;

}

.intro {
    background-image: linear-gradient(to bottom, var(--theme-45), var(--theme-15));

}

.title-s2 {
    font-size: 24px;
    line-height: 1.15;
    margin-bottom: 20px;
    color: var(--theme-10);
    margin-top: 0px;
    font-weight: 600;
}

@media (min-width: 576px) {}

@media (min-width: 768px) {}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}

@media (max-width: 576px) {

    .text-center {
        padding-top: 4rem !important;
    }

    .intro-section h1 {
        font-size: 30px;
        font-family: "Nunito Sans", sans-serif;
    }

    .sp-container {
        //padding: 0px;
    }

    .intro-section h1:first-child {
        margin-top: 40%;
    }

    .bulk-sms-w .promotional-sms {
        margin: 10px;
        padding: 20px !important;
    }

    .bulk-sms-w .transactional-sms {
        margin: 10px;
        padding: 20px !important;
    }

    .bulk-sms-w .sp-w70 {
        width: 90%;
    }

    .title-s2 {
        font-size: 26px;
    }

    .sp-p15 {
        padding: 15px;
    }

    .text-muted {
        padding-top: 5px !important;
    }


    .read-more {}

    .read-more a {
        margin: 0px;
    }



    .sticky+.body-container {
        padding-top: 70px;
    }


    .sp-container-fluid {
        padding-top: 24px;
        padding-bottom: 20px;


    }


    .our-features {
        text-align: center;
    }

    .login-button-w {
        width: 100%;
        padding-bottom: 10px;

    }
}

@media (min-width: 768px) {}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}




.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
}


.contact-us-w {}

.contact-us-w p {}

.contact-us-w a {
    text-decoration: none;
    /* color: #212529; */
}

.case-studies .case-study {
    background: #fff;
    /* margin: 0 -15px; */
    box-shadow: 0 0 10px var(--shadow-65);
    padding-bottom: 40px;
    border-radius: 10px;
    transform: scale(1);
    transition: all 0.5s ease 0s;
    padding: 30px;
    margin: 5px;
    margin-top: 50px;
    cursor: pointer;
}

.case-study-contianer-w {
    background-color: var(--theme-25);
    // rgb(32, 48, 83);


    opacity: 1;
    color: var(--theme-95);
    //  padding-top: 30px;
    padding-bottom: 40px;
}

.case-study-contianer-w p {
    font-size: 1.25rem;
}

.case-studies .case-study:hover {
    transform: scale(1.01);
    z-index: 1;
}


.text-center {
    background-color: var(--theme-45);
    opacity: 1;
    font-size: 16px;
    font-family: Rubik, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    color: rgb(217, 221, 231);
    padding-top: 8rem;



    p {
        text-align: center;
        font-size: 16px;
        word-break: normal;

    }

    .ss-txt {
        max-width: 70%;
        margin: auto;
    }


    a:hover {
        color: var(--theme-35);
        text-decoration: underline;
    }


    .footer-link {
        text-align: center;
        font-size: 16px;

        padding: 3rem;

        border-top: 1px solid var(--border-color);

        a {
            color: var(--theme-100);
            font-size: inherit;
            margin-left: 4px;
        }
    }
}


.text-uppercase {
    font-weight: 500;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 0.75rem;
    line-height: 1.15;
    margin-bottom: 1.25rem;
    color: var(--theme-100);
    text-transform: uppercase;
    font-size: 1rem;
    text-align: center;
}

.text-reset {
    color: var(--theme-100) !important;
    display: block;
}


.text-xl {
    font-weight: 400;
    font-size: 18px;
    color: rgb(65, 83, 119/1);
    font-family: Rubik, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    word-break: normal;
}

.main-heading {
    color: var(--theme-45);
    word-break: normal;
}

.sp-text1 {
    font-weight: 400;
    font-size: 16px;
    color: rgb(65, 83, 119/1);
    font-family: Rubik, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    word-break: normal;
}

.sp-text2 {
    font-size: 1rem;
    color: rgb(65, 83, 119/1);
    font-family: Rubik, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    word-break: normal;
}

.sp-color1 {
    color: rgb(65, 83, 119/1);
}

.sp-mt60 {
    margin-top: 60px;
}

.sp-mt100 {
    margin-top: 100px;
}





@media (min-width: 576px) {

    .container,
    .container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {

    .container,
    .container-sm,
    .container-md {
        max-width: 720px;
    }
}

@media (min-width: 992px) {

    .container,
    .container-sm,
    .container-md,
    .container-lg {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {

    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1140px;
    }
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}



.main-c {

    margin-top: 64px;
}

.login-button-w {

    //width: 100%;


    .login-button {
        margin-top: 28px;
        float: right;

        .btn-c {
            border-radius: 4px;
            display: inline-block;
            font-weight: 400;
            color: var(--shadow-15);
            text-align: center;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: transparent;
            border: 1px solid transparent;
            padding: 0.30rem 0.75rem;
            font-size: 0.9rem;
            line-height: 1.5;
            border-radius: 0.25rem;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            cursor: pointer;
        }

        .btn-dark {
            color: white;
            align-items: center;
            white-space: nowrap;
            border-style: solid;
            border-color: var(--theme-40);
            background-color: var(--theme-40);

        }

        .btn-dark:hover {
            color: var(--theme-100);
            background-color: var(--theme-35);
            border-color: var(--theme-35);

        }

        .btn-light {
            margin-left: 20px;
            background-color: var(--btn-light);
            color: var(--theme-100);
            border-color: var(--btn-light-border);
            opacity: 1;
        }

        .btn-light:hover {
            background-color: var(--btn-light-hover);
            color: var(--theme-100);
        }
    }

}

.sp-nav-bar {

    display: flex;
    flex-wrap: wrap;
}


@keyframes wipe-enter {
    0% {
        transform: scale(0, .025);
    }

    50% {
        transform: scale(1, .025);
    }
}