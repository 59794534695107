.login-form {
  padding: 20px;
  width: 360px;
  margin: auto;
  background-color: white;
  box-shadow: 0 3px 6px var(--shadow-85), 0 3px 6px var(--shadow-75);
  margin-top: 20vh;
}

.login-title {
  font-family: Nunito Sans;
}

.register-txt {
  cursor: pointer;
  color: var(--primary-color);
}


.p-txt {
  font: normal normal 700 14px/16px Nunito Sans;
  font-weight: 400;
}