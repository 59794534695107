@import 'primereact/resources/primereact.min.css';
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';
@import './theme.scss';
//@import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';


h2 {
    font-size: 2rem;
    font-weight: 600;
}


:root {
    --body-background: #f5f5ff;
    --primary-color: #1f7c59;
    --primary-color-dark: #14523a;
    --theme-100: #ffffff;
    --theme-98: #f6fdfb;
    --theme-97: #f4fbf8;
    --theme-95: #ebfaf4;
    --theme-90: #d6f5e9;
    --theme-85: #c2f0de;
    --theme-75: #99e6c8;
    --theme-55: #47d19c;
    --theme-50: #33cc91;
    --theme-45: #0A1530;
    --theme-40: #29a374;
    --theme-35: #248f66;
    --theme-25: #196649;
    --theme-20: #14523a;
    --theme-15: #0f3d2c;
    --theme-10: #0a291d;



    --shadow-100: #ffffff;
    --shadow-95: #f2f2f2;
    --shadow-90: #e6e6e6;
    --shadow-85: #d9d9d9;
    --shadow-80: #cccccc;
    --shadow-75: #bfbfbf;
    --shadow-65: #a6a6a6;
    --shadow-60: #999999;
    --shadow-57: #919191;
    --shadow-55: #828997;
    --shadow-53: #898484;
    --shadow-52: #848484;
    --shadow-50: #808080;
    --shadow-45: #737373;
    --shadow-40: #666666;
    --shadow-30: #4d4d4d;
    --shadow-25: #414141;
    --shadow-20: #333333;
    --shadow-15: #262626;
    --shadow-12: #1e1e1e;
    --shadow-10: #1a1a1a;
    --required: #D32F2F;
    --text-input: #545454;

    --success: #04ae70;
    --btn-light: rgb(17, 36, 64);
    --btn-light-border: rgb(65, 83, 119);
    --btn-light-hover: #415377;
    --orange-53: #ff8110;
    --orange-47: #ef6e03;
    --orange-41: #d35f00;
    --orange-92: #fbeada;
    --orange-93: #fdeadf;
    --border-color: #0d1b3f;
}


[data-theme="pink"] {
    --body-background: #ffebf2; // rgba(255, 77, 166, 0.1);
    --primary-color: #e60073;
    --primary-color-dark: #cc0066;
    --theme-100: #ffffff;
    --theme-98: #fff5fa;
    --theme-97: #fdf2f6;
    --theme-95: #ffe6f2;
    --theme-90: #ffcce6;
    --theme-85: #ffb3d9;
    --theme-75: #ff80bf;
    --theme-55: #ff1a8c;
    --theme-50: #ff0080;
    --theme-45: #0a1530;
    --theme-40: #cc0066;
    --theme-35: #b30059;
    --theme-25: #800040;
    --theme-20: #660033;
    --theme-15: #4d0026;
    --theme-10: #33001a;


    --border-color: #0d1b3f;
}

[data-theme="yellow"] {
    --body-background: #fbf8ef;
    --primary-color: #e6ac00;
    --primary-color-dark: #cc9900;
    --theme-100: #ffffff;
    --theme-98: #fffdf5;
    --theme-97: #fffbf0;
    --theme-95: #fff9e6;
    --theme-90: #fff2cc;
    --theme-85: #ffecb3;
    --theme-75: #ffdf80;
    --theme-55: #ffc61a;
    --theme-50: #ffbf00;
    --theme-45: #0a1530;
    --theme-40: #cc9900;
    --theme-35: #b38600;
    --theme-25: #806000;
    --theme-20: #664d00;
    --theme-15: #4d3900;
    --theme-10: #332600;

    --border-color: #0d1b3f;
}

[data-theme="green"] {
    --body-background: #f1f9f5;
    --primary-color: #39ac73;

    --primary-color-dark: #339966;

    --theme-100: #ffffff;
    --theme-98: #f5fff9;
    --theme-97: #f4fbf7;
    --theme-95: #ecf9f2;
    --theme-90: #d9f2e6;
    --theme-85: #c6ecd9;
    --theme-75: #9fdfbf;
    --theme-55: #53c68c;
    --theme-50: #40bf80;
    --theme-45: #0a1530;
    --theme-40: #339966;
    --theme-35: #2d8659;
    --theme-25: #206040;
    --theme-20: #194d33;
    --theme-15: #133926;
    --theme-10: #0d261a;
    --border-color: #0d1b3f;


}


[data-theme="lightblue-v1"] {
    --body-background: #f0f8ff; // rgba(255, 77, 166, 0.1);
    --primary-color: #1077da;

    --primary-color-dark: #0e69be;

    --theme-100: #ffffff;
    --theme-98: #f5faff;
    --theme-97: #f0f8ff;
    --theme-95: #e7f3fd;
    --theme-90: #d0e6fb;
    --theme-85: #b8dafa;
    --theme-75: #88c1f6;
    --theme-55: #2a90ef;
    --theme-50: #1283ed;
    --theme-45: #0a1530;
    --theme-40: #0e69be;
    --theme-35: #0c5ca6;
    --theme-25: #094277;
    --theme-20: #07345f;

    --theme-15: #052747;
    --theme-10: #041a2f;
    --border-color: #0d1b3f;


}


[data-theme="lightblue-v2"] {
    --body-background: #eef7fb;
    --primary-color: #0A9EDC;
    --primary-color-dark: #098bc3;
    --theme-100: #ffffff;
    --theme-98: #f5fdff;
    --theme-97: #f0faff;
    --theme-95: #e7f7fe;
    --theme-90: #ceeffd;
    --theme-85: #b6e7fc;
    --theme-75: #85d6f9;
    --theme-55: #24b6f5;
    --theme-50: #0baef4;
    --theme-45: #0a1530;
    --theme-40: #098bc3;
    --theme-35: #087aaa;
    --theme-25: #06577a;
    --theme-20: #054661;
    --theme-15: #043449;
    --theme-10: #022331;
    --border-color: #0d1b3f;
}



[data-theme="chocolate"] {
    --body-background: #fffdfa;
    --primary-color: #e6a100;

    --primary-color-dark: #cc8f00;

    --theme-100: #ffffff;
    --theme-98: #fcfbf7;
    --theme-97: #fdf9f2;
    --theme-95: #fff7e6;
    --theme-90: #fff0cc;
    --theme-85: #ffe8b3;
    --theme-75: #ffd980;
    --theme-55: #ffba1a;
    --theme-50: #ffb300;
    --theme-45: #281612;
    --theme-40: #cc8f00;
    --theme-35: #b37d00;
    --theme-25: #805900;
    --theme-20: #664700;
    --theme-15: #4d3600;
    --theme-10: #0d261a;


    --border-color: #462720;
}

* {
    outline: none !important;
}

body {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    font-family: Rubik, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji
}

.sp-no-p {
    padding: 0px !important;
}

.sp-no-m {
    margin: 0px !important;
}

.sp-no-pm {
    padding: 0px !important;
    margin: 0px !important;
}

.sp-left {
    float: left;
}

.sp-right {
    float: right;
}

.sp-m-lr10 {
    margin-left: 10px;
    margin-right: 10px;
}

.sp-mt2 {
    margin-top: 2px;
}


.sp-mt10 {
    margin-top: 10px !important;
}

.sp-mt20 {
    margin-top: 20px !important;
}

.sp-mt30 {
    margin-top: 30px;
}

.sp-mb5 {
    margin-bottom: 5px;
}

.sp-mb50 {
    margin-bottom: 50px;
}

.sp-p10 {
    padding: 10px;
}

.sp-p20 {
    padding: 20px !important;
}

.sp-m16 {
    margin: 16px;
}

.sp-w60 {
    width: 60%;
}

.sp-m10 {
    margin: 10px !important;
}

.sp-p20 {
    padding: 20px;
}

.sp-m20 {
    margin: 20px;
}

.sp-m2 {
    margin: 2px;
}

.sp-mlr10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.sp-mlr30 {
    margin-left: 30px;
    margin-right: 30px;
}

.sp-m30 {
    margin: 30px;
}


.sp-ml8 {
    margin-left: 8px !important;
}



.sp-ml10 {
    margin-left: 10px !important;
}


.sp-label-s1 {
    font: normal normal normal 13px/20px Nunito Sans;
    color: var(--shadow-57);
}

.sp-tbl-header {
    .sp-tbl-left {
        display: flex;

        .sp-tbl-search {
            margin-left: 20px;
            background-color: var(--body-background);
            width: 300px;
            // margin-top: 8px;
        }

        h2 {
            font-size: 1.5rem;
        }
    }

    .sp-tbl-filter {
        background: var(--body-background) 0% 0% no-repeat padding-box;
        border-radius: 5px;

        ul.sp-tbl-li {
            list-style-type: none;
            margin: 0;
            padding: 5px 10px 0px 10px;
            overflow: hidden;

            li {
                float: left;
                padding: 6px 12px;

                .sp-tbl-icon {
                    padding: 4px;
                    color: var(--primary-color);
                    cursor: pointer;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }
}

.table-li-tooltip {
    .p-tooltip-text {
        background: var(--primary-color);
        color: var(--theme-100);
        padding: 0.2rem 0.5rem;
        box-shadow: 0 2px 4px -1px var(--shadow-80), 0 4px 5px 0 var(--shadow-90), 0 1px 10px 0 var(--shadow-95);
        border-radius: 3px;
    }
}

.table-li-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: var(--primary-color);
}


.table-li-tooltip2 .p-tooltip-text {
    background: var(--shadow-65);
    color: var(--theme-100);
    padding: 0.2rem 0.5rem;
    box-shadow: 0 4px 8px 0 var(--shadow-80);
    transition: 0.3s;
    border-radius: 3px;
}

.table-li-tooltip2.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: var(--shadow-65);
}


.text-label {
    font: normal normal normal 12px/16px Nunito Sans;
    letter-spacing: 0px;
    color: var(--shadow-20);
}

.sp-tab-title {
    color: var(--shadow-10);
}



.p-inputtext-style1 {
    padding: 0.35rem 0.5rem;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-85);
    // border: 0.5px solid var(--theme-85);
    border-radius: 5px;
}

.sp-datatable {


    overflow-y: auto;

    .sp-table-d {
        //overflow-x: scroll;

        .sp-table-tbl {
            // overflow-x: scroll;
        }
    }

    .question-id {
        width: 100px;
    }

    .question {
        min-width: 600px;
    }

    .option {
        min-width: 400px;
    }

    .col-w240px {
        width: 240px;
        min-width: 240px;
    }

    .difficulty {
        width: 200px;
        min-width: 200px;
    }

    .option-2 {
        width: 500px;
        min-width: 500px;
    }

    .col-header {
        width: 1000px;
        min-width: 1000px;
    }

    .col-answer {
        min-width: 210px;
    }

}


.sp-datatable .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sp-table-title {
        margin-left: 30px;
        color: var(--shadow-10);
    }
}

.sp-datatable .product-image {
    width: 100px;
    box-shadow: 0 3px 6px var(--shadow-85), 0 3px 6px var(--shadow-75);
}

.sp-datatable .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
}

.sp-datatable .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}


.sp-main {
    width: 90%;
    margin: auto;
    margin-top: 16px;
}


.sp-toolbar {
    width: 96%;
    margin: auto;
}

.sp-active-item {
    color: var(--primary-color);

}



.sp-required {
    color: var(--required);
}


.radio-inline {
    display: inline-block;
    margin-right: 5px;
}


.sp-w200 {
    width: 200px;
}


.sp-w100p {
    width: 100% !important;
}


.sp-clearFix {
    clear: both;
}

.sp-url {
    color: var(--primary-color);
}


.sp-info-i {
    margin: 2px 6px;
    color: var(--shadow-60);
    font-size: 11px;
    max-height: 14px;
}


.custom-input2 {
    width: 100%;
    border: 1px solid var(--theme-45);
    padding: 6px;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-85);
    // border: 0.5px solid var(--theme-85);
    border-radius: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    .left {

        padding: 0px 4px;
        color: var(--shadow-53);
        font-family: inherit;
    }

    .left-b {
        border-right: 1px solid var(--shadow-60);
    }

    .right {
        color: var(--primary-color);
        font-size: 1rem;
        font-family: inherit;

    }

    .input-text {
        border: none;
        font-family: inherit;
        //width: 56%;
        width: 100%;
        // width: 56%;
        outline: none;
        margin-top: -2px;
        //padding-top: 2px;
        padding-left: 6px;
        font-size: 1rem;
        color: var(--text-input) //var(--shadow-53);
    }

    .action-text {
        width: 120px;
    }
}



.file-attach {
    width: 100%;
    height: 90px;



}


.outlined-s1 {

    border: 1px solid var(--required);
    padding: 4px 8px;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-85);
    // border: 0.5px solid var(--theme-85);
    border-radius: 5px;
    color: var(--primary-color);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.sp-pointer {
    cursor: pointer !important;
}

.sp-status {
    display: flex;

    span.sp-status-text {
        margin-top: 6px;
        margin-right: 10px;
        width: 44px;
        font: normal normal normal 11px/15px Nunito Sans;
        letter-spacing: 0px;
        color: var(--shadow-20);
    }
}



.sp-alert-box {

    .sp-alert-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50px;
    }

    .p-dialog-header {
        // padding: 8px;
        //display: none;
    }

    .sp-alert-head {
        text-align: center;
        font: normal normal bold 24px/32px Nunito Sans;
        letter-spacing: 0px;
        color: var(--shadow-12);
        margin: 5px;
    }

    .sp-alert-msg {
        text-align: center;
        font: normal normal 500 17px/23px Nunito Sans;
        letter-spacing: 0px;
        color: var(--shadow-65);
        margin: 5px;
    }
}




.sp-disabled-style {
    background-color: var(--shadow-90);
    box-shadow: none;
    color: var(--theme-75);
}


.header {
    background-color: var(--shadow-95);
    padding: 30px;
    text-align: center;
}

.content {
    padding: 16px;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.sticky+.body-container {
    padding-top: 70px;
}

.sp-success-msg {
    color: var(--success);
    font: normal normal 600 14px/19px Nunito Sans;
}


.sp-error-msg {
    font: normal normal 600 14px/19px Nunito Sans;
    color: var(--required);
    margin: 2px;
}

.sp-disabled {
    opacity: 0.6;
}




.sp-icon {
    color: var(--shadow-25) 0% 0% no-repeat padding-box;
}

.table-filter-w {
    .filter-title {
        margin-top: 0px;
    }

    .table-filter {
        padding: 20px;

        li {
            margin-bottom: 5px;
        }
    }
}



.sp-bg-white {
    background-color: var(--theme-100) !important;
}



.sp-bold {
    font-weight: bold;
}



.sp-txt-center {
    text-align: center;
}

.cont-btn {
    height: 30px;
}


.data-view {
    .label-l1 {}

    .label-v1 {
        font-size: 16px;
        font-family: Nunito Sans;
        overflow: hidden;
    }

}

.sp-card {
    border-radius: 20px;
    overflow: hidden;
    margin-top: 10px;
    //margin: 20px;
    padding: 10px;
    background-color: var(--theme-100);
    box-shadow: 0px 3px 6px var(--shadow-85);
    // display: inline-block;
}



.upload-note {
    span {
        color: var(--required);
    }

    margin-top: 0px;
    font: normal normal 500 16px/22px Nunito Sans;
    letter-spacing: 0px;
    color: var(--shadow-30);
}


.attachment-wrapper {

    //  padding: 0.35rem 0.5rem;
    background: var(--theme-100) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--shadow-85);
    // border: 0.5px solid var(--theme-85);
    border-radius: 5px;


    .attachments {
        min-height: 50px;
        max-height: 200px;
        overflow-y: auto;

    }


    .attachment-icon {
        display: inline-block;
        padding: 10px;
        cursor: pointer;

    }
}



.ck-editor__editable_inline {
    min-height: 200px;

}


.sp-nunito {
    font-family: Nunito Sans;
}


.sp-txt-overflow {
    overflow: hidden;
    word-break: break-all;
}






.s2p-1 {
    fill: var(--theme-35);
}

.s2p-2 {
    fill: var(--orange-53);
}

.s2p-5 {
    fill: var(--orange-47)
}

.s2p-6 {
    fill: var(--orange-41);
}

.s2p-20 {
    fill: var(--orange-92);
}

.s2p-3 {
    fill: var(--theme-35);
}

.s2p-4 {
    fill: var(--theme-90);
}

.s2p-7 {
    fill: var(--theme-50);
}

.s2p-8 {
    fill: var(--theme-40);
}

.s2p-9 {
    fill: var(--theme-70);
}

.s2p-10 {
    fill: var(--theme-85);
}

.s2p-11 {
    fill: var(--shadow-95);
}

.s2p-12 {
    fill: var(--theme-10);
}

.s2p-13 {
    fill: var(--theme-80);
}

.s2p-14 {
    fill: var(--theme-95);
}

.s2p-15 {
    fill: var(--shadow-95);
}

.s2p-16 {
    fill: var(--theme-40);
}

.s2p-17 {
    fill: var(--theme-40);
}

.s2p-18 {
    fill: var(--theme-95);
}

.s2p-19 {
    fill: var(--theme-55);
}

.s2p-21 {
    fill: var(--theme-40);
}

.s2p-22 {
    fill: var(--theme-100);
}

.s2p-23 {
    fill: var(--theme-25);
}

.s2p-24 {
    fill: var(--theme-50);
}

.s2p-25 {
    opacity: 0.05;
}

.s2p-26 {
    fill: var(--theme-40);
}


.sp-mt0 {
    margin-top: 0px;
}


.sp-c-address {
    overflow-wrap: normal;
    text-transform: capitalize;

}


.signature-w {

    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 4px 10px;
    border-radius: 10px;
}

.bulk-upload {


    .spread-title {
        text-align: left;
        font: normal normal normal 14px/21px Nunito Sans;
        font-weight: 600;
        letter-spacing: 0px;
        color: var(--shadow-12);
    }

    .select-file {
        margin-left: 10px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid var(--primary-color);
        border-radius: 4px;
        font: normal normal 600 13px/18px Nunito Sans;
        letter-spacing: 0px;
        color: var(--primary-color);
        padding: 5px 10px;
    }


    .refresh-btn {
        float: right;
    }

    .file-attach-name {
        padding: 0.35rem 0.5rem;
        background: var(--theme-100) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px var(--shadow-85);
        border: 0.5px solid var(--theme-85);
        border-radius: 5px;
        // height: 20px;
        height: 30px;
        min-width: 200px;
        display: inline-block;
        margin: 0px;
    }
}



.email-comaign-insights {
    background-color: white;
    margin: 30px;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    padding-bottom: 20px;

    h2 {
        font-family: Nunito Sans;
    }

    span {

        margin: 10px;
        // border: 1px solid black;
        font: normal normal normal 16px/25px Nunito Sans;
        letter-spacing: 0.1px;
        color: var(--shadow-30);
        padding: 5px;
        cursor: pointer;
    }

}

.sp-menu-active {
    border-bottom-width: 80%;

    .p-menuitem-text {
        opacity: 1.0 !important;
    }
}

.sp-sub-menu-active {
    .p-menuitem-text {
        color: var(--theme-45) !important;
    }
}


.sp-pt10 {
    padding-top: 10px;
}



.sp-menubar {

    .p-menubar-button {

        border: 0.0001rem solid rgb(65, 83, 119) !important;
    }
}



.off-logo {
    // width: 30px;
    margin-right: 10px;
    height: 30px;
    top: 24px;
    position: absolute;
}



.off-logo-large {
    height: 50px;
    top: 10px;
}

.off-logo-preview {
    // width: 30px;
    margin-left: 20px;
    height: 30px;
    // top: 24px;
    //   position: absolute;
}


.portal-bg {

    background-color: var(--theme-98);
}

.h2-defult {

    font-size: 1.5rem;
}