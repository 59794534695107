.home-section1 {
    background-color: rgba($color: #000000, $alpha: 0.5);
    min-height: calc(100vh - 0px);
    background-image: linear-gradient(to bottom, var(--theme-45), var(--theme-15));

    .home-section1-w {
        padding: 60px 20px;
        margin-top: 0px;
    }

    h1:first-child {
        margin-top: 10%;
    }

    h1 {
        font: normal normal normal 50px/60px Nunito Sans;
        margin: 4px;
        color: white;
    }

    p {
        font: normal normal normal 14px/20px Nunito Sans;
        margin: 4px;
        color: #cccccc;
        width: 50%;
    }
}

.credit-panel {

    padding: 30px;


}