.sp-menubar-w {
    // margin: 10px 20px;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    margin: auto;
    background: var(--theme-45) 0% 0% no-repeat padding-box;
    // margin-top: 10px;

    .login-txt {
        color: white;
        cursor: pointer;
        margin-right: 10px;
        //font-size: 14px;
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .app-title {
        color: white;
        display: block;
        // margin: 12px 0px 0px 10px;
        padding: 1rem;
        font-size: 24px;
        font-weight: 400;
        margin: 20px 0px 0px 10px;
        line-height: inherit;
        margin-left: 20px;

        span {
            top: -19px;
            position: relative
        }
    }

    .app-title-large {
        margin-left: 44px;
    }

    .sp-logo {
        width: 100%;
        height: 40px;
        padding: 3px 14px;
    }


    .sp-menubar {
        //    background: var(--theme-45) 0% 0% no-repeat padding-box;
        //   border-radius: 8px;
        font: normal normal bold 12px/16px Nunito Sans;
        // height: 54px;
        //  padding: 10px;

        font-weight: 600;

        font-family: Rubik,
            system-ui,
            -apple-system,
            BlinkMacSystemFont,
            Segoe UI,
            Roboto,
            Helvetica Neue,
            Arial,
            Noto Sans,
            sans-serif,
            Apple Color Emoji,
            Segoe UI Emoji,
            Segoe UI Symbol,
            Noto Color Emoji;


        img {
            cursor: pointer;
        }

        .sp-profile {
            font-size: 16px;
            font-weight: 400;
            color: var(--theme-100);
            margin-top: 38px;


            i {
                top: 3px;
                position: relative;
                cursor: pointer;
            }
        }


        .login-name {
            background-color: red;
        }

        .p-menubar-root-list {
            .p-menuitem {
                font-size: 16px;
                //   color: #fff;
            }



            .p-submenu-list {
                .p-menuitem {
                    .p-menuitem-text:hover {
                        color: var(--theme-45);
                    }
                }

                // .p-menuitem-active {
                //     background-color: var(--theme-40);


                // }

                // .p-menuitem-active:first-child .p-menuitem-link>.p-menuitem-text:first-child {
                //     color: white;

                // }
            }
        }
    }

    .p-breadcrumb ul li:last-child .p-menuitem-text {
        color: var(--theme-45);
    }
}



.footer {
    padding: 20px;
    text-align: center;

    .footer-p {
        font: normal normal normal 18px/24px Nunito Sans;
    }


    .all-rights {
        font: normal normal normal 14px/20px Nunito Sans;
    }
}


.body-container {
    min-height: calc(100vh - 70px);
}


// .sp-ellipsis {
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     width: 200px;
// }