.create-org-w {
    min-height: 70vh;
}





.field-alert {
    margin-top: 4px;
    font: normal normal normal 9px/12px Nunito Sans;
    letter-spacing: 0px;
}





.rect-logo {
    width: 160px;
    height: 40px;
}


.sqaure-logo {
    width: 40px;
    height: 40px;
}